import { Fragment, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  StepContent,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ReactCountryFlag from "react-country-flag";
import countryList from "react-select-country-list";
import countryCodeEmoji from "country-code-emoji";
import upsService from "../../../../services/ups.service";
import ToastService from "../../../toast/toast.component";
const steps = [
  "1. Introduction",
  "2. Account Information",
  "3. Agreement",
  "4. Confirmation",
];
interface UPSUserDialogProps {
  open: boolean;
  editmode: boolean;
  userData: UPSUserData;
  handleClose: () => void;
  setUpsUserData: (value: UPSUserData) => void;
  handleAddUser: () => void;
}
export interface UPSUserData {
  shipper_fullName: string;
  shipper_attentionname: string;
  shipper_taxidnum: string;
  shipper_phone: string;
  shipper_number: string;
  shipper_fax: string;
  shipper_address: string;
  shipper_city: string;
  shipper_statecode: string;
  shipper_postalcode: string;
  shipper_countrycode: string;
  shipper_selected: boolean;
}

export default function UpsUserDialog(props: UPSUserDialogProps) {
  const {
    open,
    editmode,
    userData,
    handleClose,
    handleAddUser,
    setUpsUserData,
  } = props;
  const options = useMemo(() => countryList().getData(), []);
  const [countryValue, setCountryValue] = useState("CA");

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [shipperLocation, setShipperLocationData] = useState<UPSUserData>({
    shipper_fullName: "",
    shipper_attentionname: "",
    shipper_taxidnum: "",
    shipper_phone: "",
    shipper_number: "",
    shipper_fax: "",
    shipper_address: "",
    shipper_city: "",
    shipper_statecode: "",
    shipper_postalcode: "",
    shipper_countrycode: "",
    shipper_selected: false,
  });
  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountryValue(event.target.value);
    handleShipperChange("shipper_countrycode", event.target.value);
  };
  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    handleShipperChange(name, value);
  };
  const handleShipperChange = (name: string, value: string) => {
    setShipperLocationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 0) {
        return 0; // Return the current activeStep if it is already 0
      } else {
        return prevActiveStep - 1; // Decrement the activeStep otherwise
      }
    });
  };

  const handleLogin = () => {
    localStorage.setItem("upsAccount", shipperLocation.shipper_number);
    upsService
      .validate()
      .then((data) => {
        if (data.result == "success") {
          shipperLocation.shipper_selected = false;
          upsService.addUser(shipperLocation);
          upsService.getCode(data.LassoRedirectURL, data.type);
        } else if (data.response.errors[0].message)
          ToastService.showToast("failed", data.response.errors[0].message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    handleShipperChange("shipper_countrycode", "CA");
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <ToastService.MyToastContainer />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{ sx: { width: "100%", height: "70%" } }}
      >
        <DialogTitle>{editmode ? "Edit User" : "Add User"}</DialogTitle>
        <DialogContent>
          <div className=" mt-4 mb-4">
            <Divider />
            <Typography className="text-left pt-2" gutterBottom>
              As users come and go from your organization, it's important to
              maintain historical references on their activity. Old user
              accounts are never fully deleted; instead they are deactivated and
              prevented from accessing the system.
            </Typography>
          </div>
          <Box sx={{ width: "100%" }}>
            <Stepper
              activeStep={activeStep}
              orientation="horizontal"
              alternativeLabel
              style={{ maxWidth: "100%" }} // Set the maxWidth to 100%
            >
              <Step key={"introduction"} completed={false}>
                <StepLabel>{"1. Introduction"}</StepLabel>
              </Step>
              <Step key={"accountInformation"} completed={false}>
                <StepLabel>{"2. Account Information"}</StepLabel>
              </Step>
              <Step key={"agreement"} completed={false}>
                <StepLabel>{"3. Agreement"}</StepLabel>
              </Step>
              <Step key={"confirmation"} completed={false}>
                <StepLabel>{"4. Confirmation"}</StepLabel>
              </Step>
            </Stepper>

            {activeStep === steps.length ? (
              <Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleLogin}>Login</Button>
                </Box>
              </Fragment>
            ) : (
              <Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Step {activeStep + 1}
                </Typography>

                {/* step content */}
                <Collapse in={activeStep === 0}>
                  <div className="flex">
                    <div className="h-[250px] w-9/12 m-3">
                      <Typography variant="h6" className="text-left mb-2 pb-2">
                        UPS Developer Kit License and Registration Wizard
                      </Typography>
                      <Divider />
                      <p>
                        This wizard will assist you in completing the necessary
                        licensing and registration requirements to activate and
                        use the UPS Developer Kit from within ShipStation{" "}
                      </p>
                      <br />
                      <p>
                        If you do not wish to use any of the functions that
                        utilize the UPS Developer Kit, click the Cancel button
                        and this provider will not be enabled.{" "}
                      </p>
                      <br />
                      <p>
                        {" "}
                        If, at a later time, you wish to use the UPS Developer
                        Kit, return to this section and complete the UPS
                        Developer Kit licensing and registration process.
                      </p>
                    </div>
                    <div className="h-[250px] w-3/12 m-4">
                      <img src="/ups.svg" alt="" className="w-36 h-36" />
                    </div>
                  </div>
                </Collapse>

                <Collapse in={activeStep === 1}>
                  <div className="h-[250px] m-2">
                    <Grid container spacing={2}>
                      <Grid xs={12} sx={{ p: 1 }} md={12} xl={12}>
                        <Typography
                          textAlign={"left"}
                          variant="h6"
                          className="text-center"
                          gutterBottom
                        >
                          Register your UPS Account
                        </Typography>
                        <Divider />
                      </Grid>
                      <Grid
                        container
                        xs={6}
                        sx={{ p: 2, border: "1px solid #ccc" }}
                      >
                        <Grid xs={6} sx={{ p: 1 }} md={6} xl={6}>
                          <TextField
                            className="w-full"
                            variant="outlined"
                            label="Contact Name"
                            name="shipper_fullName"
                            value={shipperLocation.shipper_fullName}
                            size="small"
                            onChange={handleLabelChange}
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: "14px",
                              },
                              "& .MuiOutlinedInput-input": {
                                fontSize: "14px",
                              },
                            }}
                          />
                        </Grid>
                        <Grid xs={6} sx={{ p: 1 }} md={6}>
                          <TextField
                            className="w-full"
                            variant="outlined"
                            label="Company"
                            name="shipper_attentionname"
                            value={shipperLocation.shipper_attentionname}
                            size="small"
                            onChange={handleLabelChange}
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: "14px",
                              },
                              "& .MuiOutlinedInput-input": {
                                fontSize: "14px",
                              },
                            }}
                          />
                        </Grid>
                        <Grid xs={6} sx={{ p: 1 }} md={6}>
                          <TextField
                            className="w-full"
                            variant="outlined"
                            label="TaxIdentificationNumber"
                            name="shipper_taxidnum"
                            value={shipperLocation.shipper_taxidnum}
                            size="small"
                            onChange={handleLabelChange}
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: "14px",
                              },
                              "& .MuiOutlinedInput-input": {
                                fontSize: "14px",
                              },
                            }}
                          />
                        </Grid>
                        <Grid xs={6} sx={{ p: 1 }} md={6}>
                          <TextField
                            className="w-full"
                            variant="outlined"
                            label="Phone"
                            name="shipper_phone"
                            value={shipperLocation.shipper_phone}
                            size="small"
                            onChange={handleLabelChange}
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: "14px",
                              },
                              "& .MuiOutlinedInput-input": {
                                fontSize: "14px",
                              },
                            }}
                          />
                        </Grid>

                        <Grid xs={6} sx={{ p: 1 }} md={6}>
                          <TextField
                            className="w-full"
                            variant="outlined"
                            label="FaxNo"
                            name="shipper_fax"
                            value={shipperLocation.shipper_fax}
                            size="small"
                            onChange={handleLabelChange}
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: "14px",
                              },
                              "& .MuiOutlinedInput-input": {
                                fontSize: "14px",
                              },
                            }}
                          />
                        </Grid>

                        <Grid xs={6} sx={{ p: 1 }} md={6}>
                          <TextField
                            className="w-full"
                            variant="outlined"
                            label="Address Line"
                            name="shipper_address"
                            value={shipperLocation.shipper_address}
                            size="small"
                            onChange={handleLabelChange}
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: "14px",
                              },
                              "& .MuiOutlinedInput-input": {
                                fontSize: "14px",
                              },
                            }}
                          />
                        </Grid>

                        <Grid xs={6} sx={{ p: 1 }} md={6}>
                          <TextField
                            className="w-full"
                            variant="outlined"
                            label="City"
                            name="shipper_city"
                            value={shipperLocation.shipper_city}
                            size="small"
                            onChange={handleLabelChange}
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: "14px",
                              },
                              "& .MuiOutlinedInput-input": {
                                fontSize: "14px",
                              },
                            }}
                          />
                        </Grid>
                        <Grid xs={6} sx={{ p: 1 }} md={6}>
                          <TextField
                            className="w-full"
                            variant="outlined"
                            label="State Code"
                            name="shipper_statecode"
                            value={shipperLocation.shipper_statecode}
                            size="small"
                            onChange={handleLabelChange}
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: "14px",
                              },
                              "& .MuiOutlinedInput-input": {
                                fontSize: "14px",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        xs={6}
                        sx={{ p: 2, border: "1px solid #ccc" }}
                        // sx={{
                        //   p: 1,
                        //   border: "1px solid #ccc",
                        //   boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                        // }}
                      >
                        <Grid xs={12} sx={{ p: 1 }} md={12}>
                          <Typography>
                            Please confirm your address on file by entering your
                            information.
                          </Typography>
                        </Grid>
                        <Grid xs={12} sx={{ p: 1 }} md={12}>
                          <TextField
                            className="w-full"
                            variant="outlined"
                            label="Shipper No"
                            name="shipper_number"
                            value={shipperLocation.shipper_number}
                            size="small"
                            onChange={handleLabelChange}
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: "14px",
                              },
                              "& .MuiOutlinedInput-input": {
                                fontSize: "14px",
                              },
                            }}
                          />
                        </Grid>
                        <Grid xs={12} sx={{ p: 1 }} md={12}>
                          <Typography>
                            You can find your account number (aka "Shipper
                            Number") by logging into your "My UPS" account and
                            selecting "Account Summary"
                          </Typography>
                        </Grid>
                        <Grid xs={12} sx={{ p: 1 }} md={12}>
                          <div className="flex">
                            <ReactCountryFlag
                              className="mr-1 border border-gray-200 shadow-md rounded-md"
                              style={{ width: "32px", height: "32px" }}
                              countryCode={countryValue ? countryValue : ""}
                              svg
                              cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                              cdnSuffix="svg"
                              title={countryValue ? countryValue : ""}
                            />
                            <Select
                              value={countryValue}
                              size="small"
                              name="shipper_countrycode"
                              onChange={handleCountryChange}
                              className="w-full"
                              sx={{
                                fontSize: "14px",
                              }}
                            >
                              {options.map((country) => (
                                <MenuItem
                                  id={country.value}
                                  key={country.value}
                                  value={country.value}
                                  sx={{
                                    fontSize: "14px",
                                  }}
                                >
                                  {"(" +
                                    countryCodeEmoji(country.value) +
                                    ") " +
                                    country.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </Grid>
                        <Grid xs={12} sx={{ p: 1 }} md={12}>
                          <TextField
                            className="w-full"
                            variant="outlined"
                            label="Postal Code"
                            name="shipper_postalcode"
                            value={shipperLocation.shipper_postalcode}
                            size="small"
                            onChange={handleLabelChange}
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: "14px",
                              },
                              "& .MuiOutlinedInput-input": {
                                fontSize: "14px",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Collapse>

                <Collapse in={activeStep === 2}>
                  <div className="h-[250px] m-3">
                    <Typography>Agreement</Typography>
                    <Divider />
                    <p>
                      This wizard will assist you in completing the necessary
                      licensing and registration requirements to activate and
                      use the UPS Developer Kit from within ShipStation If you
                      do not wish to use any of the functions that utilize the
                      UPS Developer Kit, click the Cancel button and this
                      provider will not be enabled. If, at a later time, you
                      wish to use the UPS Developer Kit, return to this section
                      and complete the UPS Developer Kit licensing and
                      registration process.
                    </p>
                  </div>
                </Collapse>
                <Collapse in={activeStep === 3}>
                  <div className="h-[250px] m-3">
                    <Typography>Confirmation</Typography>
                    <Divider />
                    <p>
                      This wizard will assist you in completing the necessary
                      licensing and registration requirements to activate and
                      use the UPS Developer Kit from within ShipStation
                    </p>
                  </div>
                </Collapse>
              </Fragment>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <button
              type="submit"
              className="w-[120px] h-[42px] text-center align-middle ml-4 px-4 py-4 tracking-wide text-2xl -skew-x-6 text-white transition-colors duration-200 transform bg-baseColor rounded-md hover:bg-baseFocusColor focus:outline-none focus:bg-baseFocusColor1 flex items-center justify-center"
              onClick={handleBack}
            >
              <span className="text-white text-base">Back</span>
            </button>

            {/* <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}

            {activeStep > steps.length - 1 ? (
              ""
            ) : activeStep === steps.length - 1 ? (
              <button
                type="submit"
                className="w-[120px] h-[42px] text-center align-middle ml-4 px-4 py-4 tracking-wide text-2xl -skew-x-6 text-white transition-colors duration-200 transform bg-baseColor rounded-md hover:bg-baseFocusColor focus:outline-none focus:bg-baseFocusColor1 flex items-center justify-center"
                onClick={handleNext}
              >
                <span className="text-white text-base">Finish</span>
              </button>
            ) : (
              <button
                type="submit"
                className="w-[120px] h-[42px] text-center align-middle ml-4 px-4 py-4 tracking-wide text-2xl -skew-x-6 text-white transition-colors duration-200 transform bg-baseColor rounded-md hover:bg-baseFocusColor focus:outline-none focus:bg-baseFocusColor1 flex items-center justify-center"
                onClick={handleNext}
              >
                <span className="text-white text-base">Next</span>
              </button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
